import React, { useState } from "react";
import myCasesIcon from "../../../assets/icons/generic/icon_meine-faelle.png";
import ServiceWorldContentBox from "../ServiceWorldContentBox";
import _map from "lodash/map";
import ServiceWorldCaseItem from "./ServiceWorldCaseItem";
import { MyCasesLoader } from "../../ContentLoader/MyCasesLoader";
import { Box, CardActionArea, Collapse, Divider, Grid, List } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import serviceWorldMyCasesStyle from "./serviceWorldMyCasesStyle";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/reactQuery/reactQueryService";
import { splitUpByCompletedStatus } from "../../../services/serviceWorldService";

const ServiceWorldMyCases = ({ NotificationComponent, listItemButtonConfig }) => {
  const [expandClosedCases, setExpandClosedCases] = useState(false);
  const { data: minimalCases, isLoading } = useQuery(queryKeys.collection("/collection/cases"), () => fetchCollection("/collection/cases"));

  if (isLoading) {
    return (
      <Box sx={serviceWorldMyCasesStyle.myCasesContainer}>
        <Box sx={serviceWorldMyCasesStyle.scrollToAnchor} id={"my-cases"} />
        <ServiceWorldContentBox headline={"Meine Fälle"} icon={myCasesIcon}>
          <MyCasesLoader />
        </ServiceWorldContentBox>
      </Box>
    );
  }

  const { cases, closedCases } = splitUpByCompletedStatus(minimalCases);

  const CasesStatus = () => {
    switch (true) {
      case cases.length === 0 && closedCases.length === 0:
        return (
          <>
            <Divider sx={serviceWorldMyCasesStyle.divider} />
            <Box sx={serviceWorldMyCasesStyle.noCase}>Kein Fall vorhanden</Box>
          </>
        );
      case cases.length === 0 && closedCases.length > 0:
        return (
          <>
            <Divider sx={serviceWorldMyCasesStyle.divider} />
            <Box sx={serviceWorldMyCasesStyle.noCase}>Kein aktiver Fall vorhanden</Box>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={serviceWorldMyCasesStyle.myCasesContainer}>
      <ServiceWorldContentBox headline={"Meine Fälle"} icon={myCasesIcon}>
        <CasesStatus />
        {cases.length > 0 && (
          <List disablePadding>
            {_map(cases, (minimalCase) => (
              <React.Fragment key={minimalCase.id}>
                <ServiceWorldCaseItem minimalCase={minimalCase} NotificationComponent={NotificationComponent} listItemButtonConfig={listItemButtonConfig} />
              </React.Fragment>
            ))}
          </List>
        )}
        {closedCases.length > 0 && (
          <>
            <CardActionArea
              onClick={() => setExpandClosedCases(!expandClosedCases)}
              sx={{
                ...serviceWorldMyCasesStyle.expandClosedCases,
                ...(expandClosedCases ? {} : { borderRadius: "0 0 25px 25px" }),
              }}
            >
              <Grid container alignItems={"center"} justifyContent={"center"} spacing={3}>
                <Grid item>
                  <Typography variant={"h6"} sx={serviceWorldMyCasesStyle.expandClosedCasesText}>
                    Abgeschlossene Fälle
                  </Typography>
                </Grid>
                <Grid item>{expandClosedCases ? <ExpandLess /> : <ExpandMore />}</Grid>
              </Grid>
            </CardActionArea>
            <Collapse in={expandClosedCases}>
              {_map(closedCases, (minimalCase, index) => (
                <React.Fragment key={minimalCase.id}>
                  <ServiceWorldCaseItem
                    minimalCase={minimalCase}
                    withDivider={index > 0}
                    NotificationComponent={NotificationComponent}
                    listItemButtonConfig={listItemButtonConfig}
                  />
                </React.Fragment>
              ))}
            </Collapse>
          </>
        )}
      </ServiceWorldContentBox>
    </Box>
  );
};

export default ServiceWorldMyCases;
