import React from "react";
import ContentBox from "../../Box/ContentBox/ContentBox";
import messageIcon from "../../../assets/icons/employment/icon_dismissal_250px.png";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Conversation } from "../../../types/Entities/Messaging/Conversation";
import { fetchCollection, queryKeys } from "../../../services/reactQuery/reactQueryService";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import { caseHasUnreadMessage } from "../../../services/messagingService";

type NewMessageCaseBoxProps = {
  product: AbstractCase;
};
export default function NewMessageCaseBox({ product }: NewMessageCaseBoxProps) {

  const { data: conversations, isLoading } = useQuery<Array<Conversation>, Error>(queryKeys.collection("/conversations"), () =>
    fetchCollection("/conversations")
  );

  if (isLoading || !conversations) {
    return null;
  }

  if (!caseHasUnreadMessage(product.id, conversations)) {
    return null;
  }

  return (
    <ContentBox icon={messageIcon} headline={"Neue Nachricht"}>
      <Stack spacing={2}>
        <Typography textAlign={"center"}>Es liegt eine neue Nachricht für Sie vor. Sie finden diese in Ihrem Nachrichtenbereich.</Typography>
        <Button variant={"contained"} fullWidth component={Link} to={"/servicewelt/nachrichten?caseId=" + product.id}>
          Zum Nachrichtenbereich
        </Button>
      </Stack>
    </ContentBox>
  );
}
