import React from "react";
import * as PropTypes from "prop-types";
import ContentBox from "../../Box/ContentBox/ContentBox";
import statusIcon from "../../../assets/icons/serviceOverview/icon_status_128px.png";
import Status from "../../Status/Status";
import { getStatusBoxConfig } from "../../../services/serviceWorldService";

const StatusBox = ({ product }) => {
  if (!product) {
    return null;
  }

  const statusConfig = getStatusBoxConfig(product);

  const ExtraContent = () => statusConfig.extraContent || null;

  return (
    <ContentBox icon={statusIcon} headline={"Status"} alignContent={"stretch"}>
      <Status
        ctaButton={statusConfig.ctaButton}
        maxPercentage={statusConfig.percentage}
        nextStepContent={{
          header: statusConfig.header,
          text: statusConfig.text,
        }}
        productName={statusConfig.productName}
      />
      <ExtraContent />
    </ContentBox>
  );
};

StatusBox.propTypes = {
  product: PropTypes.object.isRequired,
};

StatusBox.defaultProps = {};

export default StatusBox;
