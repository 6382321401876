import React, { useEffect, useRef } from "react";
import { Conversation } from "../../../types/Entities/Messaging/Conversation";
import Grid from "@mui/material/Grid";
import ServiceWorldContentBox from "../../Serviceworld/ServiceWorldContentBox";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MessageCenterMessage from "./MessageCenterMessage";
import { ArrowBackOutlined, Reply } from "@mui/icons-material";
import { getNewMessageLink, markMessagesAsRead } from "../../../services/messagingService";
import { queryKeys } from "../../../services/reactQuery/reactQueryService";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Message } from "../../../types/Entities/Messaging/Message";
import { scrollToAnchor } from "../../../theme/commonStyle";
import { MinimalCase } from "../../../types/Entities/MinimalCase";

type MessageCenterConversationProps = {
  conversation: Conversation;
  setOpenConversationId: (conversationId: number | null) => void;
  product: MinimalCase;
};

export default function MessageCenterConversation({ conversation, setOpenConversationId, product }: MessageCenterConversationProps) {
  const resetConversation = () => setOpenConversationId(null);
  const queryClient = useQueryClient();
  const conversationRef = useRef<null | HTMLDivElement>();

  const sortComparisonFunction = (MessageA: Message, MessageB: Message) => {
    if (MessageA.created < MessageB.created) {
      return -1;
    }
    if (MessageA.created > MessageB.created) {
      return 1;
    }
    return 0;
  };

  const sortedMessages = conversation.containedMessages.sort(sortComparisonFunction);

  useEffect(() => {
    markMessagesAsRead(conversation.containedMessages).then(() => {
      queryClient.invalidateQueries(queryKeys.collection("/conversations"));
    });
    conversationRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      <Box ref={conversationRef} sx={scrollToAnchor} />
      <ServiceWorldContentBox headline={conversation.subject}>
        <hr />
        <Box sx={{ marginLeft: ".5rem" }}>
          <Button onClick={resetConversation} startIcon={<ArrowBackOutlined />}>
            Zurück
          </Button>
        </Box>
        <Box sx={{ padding: "0 1rem", margin: "0 1rem" }}>
          {sortedMessages.map((message, index) => (
            <React.Fragment key={message.id}>
              {index === sortedMessages.length - 1 && <Box ref={conversationRef} sx={scrollToAnchor} />}
              <MessageCenterMessage message={message} product={product} />
            </React.Fragment>
          ))}
          {conversation.isClosed && (
            <Typography textAlign={"center"} fontWeight={"bold"}>
              Diese Unterhaltung wurde beendet. Sie können nicht mehr darauf antworten.
            </Typography>
          )}
          <Grid container justifyContent={"center"}>
            <Grid item>
              <Button
                disabled={conversation.isClosed}
                startIcon={<Reply />}
                component={Link}
                to={getNewMessageLink(conversation.channel.caseName, conversation.channel.caseId!, conversation.id)}
              >
                Antworten
              </Button>
            </Grid>
          </Grid>
        </Box>
      </ServiceWorldContentBox>
    </>
  );
}
