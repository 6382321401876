import {
  STATUS_CHECKED,
  STATUS_COMPLETED,
  STATUS_CREATED,
  STATUS_DATA_COMPLETED,
  STATUS_DATE_SET_SETTLEMENT,
  STATUS_DATE_SET_TRIAL,
  STATUS_DECISION_MADE,
  STATUS_DOCUMENTS_COMPLETE,
  STATUS_PAID,
  STATUS_POWER_OF_ATTORNEY_GRANTED,
  STATUS_SUBMITTED,
} from "./leadStatus";
import { APPLICATION_STATUS_DOCUMENTS } from "./applicationStatus";
import moment from "moment";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import React from "react";
import { getCaseLink, getProductData } from "../productService";

const getContent = (settlement) => {
  let nextStepContent = {
    header: "",
    text: "",
  };

  switch (settlement.leadStatus) {
    case STATUS_CREATED:
      nextStepContent.header = "Jetzt Angaben vervollständigen und Kündigung prüfen lassen!";
      nextStepContent.text = "Es fehlen nur noch wenige Angaben, damit Ihre Kündigung kostenlos durch uns geprüft werden kann.";
      break;
    case STATUS_DATA_COMPLETED:
      nextStepContent.header = "Ihre Vollmacht wird benötigt";
      nextStepContent.text =
        "Damit Ihre Kündigung kostenlos geprüft werden kann und wir - wenn gewünscht - eine Klage einreichen können, benötigen wir Ihre Vollmacht.";
      break;
    case STATUS_POWER_OF_ATTORNEY_GRANTED:
      // Following condition is to guarantee that a customer does not see requirement for uploading missing documents
      if (settlement.applicationStatus === APPLICATION_STATUS_DOCUMENTS) {
        nextStepContent.header = "Ihre Angaben sind fast vollständig!";
        nextStepContent.text = "Zum Einreichen des Antrags bei Gericht fehlt noch: Kopie des Kündigungsschreibens | Kopie des Arbeitsvertrags";
        break;
      }
    // fallsthrough
    case STATUS_DOCUMENTS_COMPLETE:
      nextStepContent.header = "Ihre Angaben sind vollständig und werden direkt von uns überprüft!";
      nextStepContent.text = "Wir melden uns nun umgehend telefonisch bei Ihnen, um offene Fragen zu klären und die Klage zu finalisieren.";
      break;
    case STATUS_CHECKED:
      if (settlement.legalExpenseInsurance === true) {
        nextStepContent.header = "Kündigungsklage kann eingereicht werden";
        nextStepContent.text =
          "Ihre Angaben sind vollständig und überprüft worden. Sobald die Deckungszusage von Ihrer Versicherung eingegangen ist, reichen wir die Klage umgehend ein.";
      } else {
        nextStepContent.header = "Kündigungsklage kann eingereicht werden";
        nextStepContent.text = "Ihre Angaben sind vollständig und von uns überprüft worden. Wir reichen die Klage nun umgehend für Sie ein.";
      }
      break;
    case STATUS_SUBMITTED:
    case STATUS_PAID:
      nextStepContent.header = "Ihre Kündigungsschutzklage wurde eingereicht!";
      nextStepContent.text =
        "Wir haben die Klage an das zuständige Gericht übermittelt. Das Gericht wird in Kürze reagieren und einen Gütetermin festsetzen. In der Zwischenzeit wird Ihr Anwalt mit Ihrem Arbeitgeber über weitere Schritte verhandeln.";
      break;
    case STATUS_DATE_SET_SETTLEMENT:
      nextStepContent.header = "Der Gütetermin zu Ihrer Klage wurde festgesetzt!";
      nextStepContent.text =
        "Wenn zuvor keine Einigung mit dem Arbeitgeber gefunden wurde, findet der Gütetermin am " +
        moment(settlement.settlementCourtAppointment.courtDate.appointmentDateTime, moment.ISO_8601, true).format("DD.MM.YYYY [um] HH:mm [Uhr]") +
        " statt. " +
        "Ihr Anwalt vertritt Sie bei diesem Termin. Sie müssen nur anwesend sein, wenn das Gericht Sie persönlich geladen hat.";
      break;
    case STATUS_DATE_SET_TRIAL:
      nextStepContent.header = "Der Kammertermin zu Ihrer Klage wurde festgesetzt!";
      nextStepContent.text =
        "Der Kammertermin findet am " +
        moment(settlement.trialCourtAppointment.courtDate.appointmentDateTime, moment.ISO_8601, true).format("DD.MM.YYYY [um] HH:mm [Uhr]") +
        " statt. Das Schreiben zum Kammertermin finden Sie hier in Ihrer Servicewelt. Wichtig zu wissen: Sie müssen bei dem Termin persönlich anwesend sein.";
      break;
    case STATUS_DECISION_MADE:
      if (settlement.settlementReachedDate === null) {
        nextStepContent.header = "Das Ergebnis Ihrer Kündigungsschutzklage steht fest!";
        nextStepContent.text =
          "Alle Unterlagen zum Ergebnis der Klage finden Sie in Ihrer Servicewelt. Sobald die Berufungsfrist abgelaufen ist, ist Ihr Fall abgeschlossen. Bei Fragen stehen wir und das Serviceteam auch zukünftig jederzeit gerne zur Verfügung!";
      } else {
        nextStepContent.header = "Erfolgreicher Vergleich";
        if (settlement.noWinNoFee) {
          nextStepContent.text =
            "Es wurde erfolgreich ein Vergleich mit Ihrem Arbeitgeber erwirkt. Das Protokoll zu dem Vergleich finden Sie in Ihrer Servicewelt. Sobald die Widerrufsfrist abgelaufen ist, ist Ihr Fall abgeschlossen. Für die erfolgreiche Umsetzung Ihres Falles wird nun die Erfolgsprovision fällig, die wir nun noch in Rechnung stellen werden.";
        } else {
          nextStepContent.text =
            "Es wurde erfolgreich ein Vergleich mit Ihrem Arbeitgeber erwirkt. Das Protokoll zu dem Vergleich finden Sie in Ihrer Servicewelt. Sobald die Widerrufsfrist abgelaufen ist, ist Ihr Fall abgeschlossen. Für die Einigung wird eine Gebühr fällig, die wir Ihnen nun noch in Rechnung stellen werden.";
        }
      }
      break;
    case STATUS_COMPLETED:
      nextStepContent.header = "Das Ergebnis Ihrer Kündigungsschutzklage steht fest!";
      nextStepContent.text =
        "Alle Unterlagen zum Ergebnis der Klage finden Sie in Ihrer Servicewelt. Bei Fragen stehen wir Ihnen und das Serviceteam auch zukünftig jederzeit zur Verfügung!";
      break;
    default:
      break;
  }

  return nextStepContent;
};

const getButton = (settlement) => {
  let target = "";
  let text = "";

  switch (settlement.leadStatus) {
    case STATUS_CREATED:
      target = getCaseLink(settlement) + "/antrag";
      text = "Angaben vervollständigen";
      break;

    case STATUS_DATA_COMPLETED:
      target = getCaseLink(settlement) + "/antrag/vollmacht";
      text = "Zur Vollmacht";
      break;

    case STATUS_POWER_OF_ATTORNEY_GRANTED:
      if (settlement.applicationStatus === APPLICATION_STATUS_DOCUMENTS) {
        target = getCaseLink(settlement) + "/antrag/dokumente-hochladen";
        text = "Dokumente hochladen";
        break;
      }
      return null;

    default:
      return null;
  }

  return (
    <Button component={Link} to={target} variant="contained" color="primary" fullWidth>
      {text}
    </Button>
  );
};

const getPercentage = (settlement) => {
  return (Math.max(1, settlement.leadStatus) / 11) * 10;
};

export const getStatusBoxConfigSettlement = (settlement) => {
  let statusBoxConfig = {
    header: "Es ist ein Fehler aufgetreten",
    text: "",
    ctaButton: null,
    productName: "",
    percentage: 100,
  };

  const ctaButton = getButton(settlement);
  const content = getContent(settlement);

  statusBoxConfig.header = content.header;
  statusBoxConfig.text = content.text;
  statusBoxConfig.ctaButton = ctaButton;
  statusBoxConfig.productName = getProductData(settlement.name, "label");
  statusBoxConfig.percentage = getPercentage(settlement);

  return statusBoxConfig;
};
