const legalFieldPageStyle = {
  centeredText: {
    textAlign: "center",
    marginTop: "2rem",
  },
  image: {
    display: "block",
    maxWidth: 300,
    height: "auto",
    margin: "0 auto",
  },
};

export default legalFieldPageStyle;
