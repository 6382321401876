import React from "react";
import iconUpload from "../../assets/img/generic/icon_upload.png";
import { Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import {getCaseLink, getProductData} from "../../services/productService";
import ContentBox from "../Box/ContentBox/ContentBox";
import { AbstractCase } from "../../types/Entities/AbstractCase";

type ServiceWorldFileUploadLinkBoxProps = {
  product: AbstractCase;
};

export default function ServiceWorldFileUploadLinkBox({ product }: ServiceWorldFileUploadLinkBoxProps) {

  if (!getProductData(product.name, "mediaObjectUploadAllowed")({product})) {
    return null;
  }

  return (
    <ContentBox headline="Datei auswählen" icon={iconUpload} elevation={4} alignContent={"stretch"} loading={false}>
      <Stack alignItems={"center"} spacing={2}>
        <Typography>Über den Dateimanager können Sie jederzeit Dateien und Dokumente zu Ihrem Fall hochladen.</Typography>
        <Button fullWidth variant={"contained"} component={Link} to={getCaseLink(product) + "/dokumente-hochladen"}>
          Dateien hochladen
        </Button>
      </Stack>
    </ContentBox>
  );
};
