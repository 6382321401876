const greyBackgroundWrapperStyle = {
  greyBackground: {
    backgroundColor: "#F4F4F4",
  },
  verticalPadding: {
    padding: "2rem 0",
  },
};

export default greyBackgroundWrapperStyle;
