import React from "react";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { getCaseLink } from "../../../services/productService";
import checkedIcon from "../../../assets/img/generic/icon_check_150px.png";

type UploadCompletedProps = {
  product: AbstractCase;
};

export default function UploadCompleted({ product }: UploadCompletedProps) {
  return (
    <Stack alignItems={"center"} spacing={3}>
      <Box component={"img"} sx={{ height: "100px", width: "100px" }} src={checkedIcon} />
      <Typography>Ihre Dokumente wurden erfolgreich an unseren Kundenservice übermittelt.</Typography>
      <Button fullWidth={false} component={Link} to={getCaseLink(product)} variant={"contained"}>
        Zurück zur Servicewelt
      </Button>
    </Stack>
  );
}
