const messageCenterMessageStyle = {
  messageContainer: { margin: "0 1rem 1rem 1rem", width: "90%", color: "black" },
  messageBubble: { padding: "1rem", borderRadius: "1rem" },
  ownMessageContainer: { marginLeft: "10%" },
  otherMessageContainer: {},
  ownMessageBubble: { backgroundColor: "#c9e1e1" },
  otherMessageBubble: { backgroundColor: "#F4F4F4" },
  readBox: { textAlign: "right", paddingRight: "1rem" },
  customerFeedbackBox: { padding: "1rem" },
};
export default messageCenterMessageStyle;
