import React from "react";
import * as PropTypes from "prop-types";
import DivorceRealCostsBox from "./DivorceRealCostsBox";
import DivorceCostCalculator from "./DivorceCostCalculator";
import { STATUS_DATA_COMPLETED } from "../../../../services/divorce/leadStatus";
import ContentBox from "../../../Box/ContentBox/ContentBox";
import costIcon from "../../../../assets/icons/serviceOverview/icon_costs_128px.png";

const DivorceCostBox = ({ divorce }) => {
  if (divorce.isInitialProxy || divorce.leadStatus < STATUS_DATA_COMPLETED) {
    return (
      <ContentBox icon={costIcon} headline={"Kostenrechner"} alignContent={"stretch"} loading={false}>
        <DivorceCostCalculator />
      </ContentBox>
    );
  }

  return (
    <ContentBox icon={costIcon} headline={"Kostenübersicht"} alignContent={"stretch"} loading={!divorce}>
      <DivorceRealCostsBox divorce={divorce} />
    </ContentBox>
  );
};

DivorceCostBox.propTypes = {
  divorce: PropTypes.object.isRequired,
};

export default DivorceCostBox;
